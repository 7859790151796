<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route() {
      // 路由变化的时候，让滚动数据变为0
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
/* @font-face {
  font-family: "SourceHanSansCN";
  src: url(./assets/font/SourceHanSansCN-Normal.otf);
} */
@font-face {
  font-family: "D-DIN";
  src: url(./assets/font/D-DIN.ttf);
}
/* @font-face {
  font-family: "SourceHanSansCN-Bold";
  src: url(./assets/font/SourceHanSansCN-Bold.otf);
}
@font-face {
  font-family: "SourceHanSansCN-Light";
  src: url(./assets/font/SourceHanSansCN-Light.otf);
} */
#app {
  /* font-family: SourceHanSansCN; */
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
* {
  padding: 0;
  margin: 0;
}
video {
  display: block;
  background: #000;
  object-fit: cover;
}
a {
  color: #fff;
  text-decoration: none;
}
@media screen and (max-width: 1000px) {
#app {
  width: 100vw;
  font-size: 1rem;
}
}
</style>
