import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/main',
    name: '_home',
    component: () => import('@/components/main'),
    redirect: {name: 'product'},
    children: [
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/product/index'),
        redirect: {name: 'glucose'},
        children: [
          {
            path: 'glucose',
            name: 'glucose',
            component: () => import('@/views/product/detail/glucose')
          },
          {
            path: 'temperature',
            name: 'temperature',
            component: () => import('@/views/product/detail/temperature')
          },
          {
            path: 'sweat',
            name: 'sweat',
            component: () => import('@/views/product/detail/sweat')
          },
          {
            path: 'urine',
            name: 'urine',
            component: () => import('@/views/product/detail/urine')
          },
          {
            path: 'signalDisk',
            name: 'signalDisk',
            component: () => import('@/views/product/detail/signalDisk')
          },
          {
            path: 'signalCapsule',
            name: 'signalCapsule',
            component: () => import('@/views/product/detail/signalCapsule')
          },
          {
            path: 'droplet',
            name: 'droplet',
            component: () => import('@/views/product/detail/droplet')
          }
        ]
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact'),
      },
      {
        path: '/mall',
        name: 'mall',
        component: () => import('@/views/mall'),
      },
      {
        path: '/toRefresh',
        name: 'toRefresh',
        component: () => import('@/views/toRefresh'),
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    redirect: {name: 'news-list'},
    component: () => import('@/components/main'),
    children: [
      {
        path: 'list',
        name: 'news-list',
        component: () => import('@/views/news/list')
      },
      {
        path: 'detail',
        name: 'news-detail',
        component: () => import('@/views/news/detail')
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    path: '/tempInstruction',
    name: 'tempInstruction',
    component: () => import('@/views/product/tempInstruction'),
  },
  {
    path: '/tempInstruction-jingjie',
    name: 'tempInstruction-jingjie',
    component: () => import('@/views/product/tempInstruction-jingjie'),
  },
  {
    path: '/load',
    name: 'load',
    component: () => import('@/views/load/list'),
  },
  {
    path: '/document',
    name: 'document',
    component: () => import('@/views/document/list'),
  },
  {
    path: '/document-sweat',
    name: 'document-sweat',
    component: () => import('@/views/document/sweat'),
  },
  {
    path: '/document-biosys',
    name: 'document-biosys',
    component: () => import('@/views/document/biosys'),
  },
  {
    path: '/document-biodrop',
    name: 'document-biodrop',
    component: () => import('@/views/document/biodrop'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;